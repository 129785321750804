import React, { useEffect, useState } from 'react';
import {
    Menu,
    MenuButton,
    MenuList,
    Button,
    Flex,
    Text,
    useTheme,
    useMediaQuery,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Box
} from '@chakra-ui/react';
import { FaBorderAll } from 'react-icons/fa';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { capitalizeFirstLetter } from '../../resource/validate';
import { categoriesList } from '../../resource/save';
import ListSubCategoriesMaster from './ListSubCategoriesMaster';
import {MinusIcon, AddIcon} from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

const ButtonCategoriesMb = () => {
    const [categories, setCategories] = useState(null);
    const [selectedCategoryGeneral, setSelectedCategoryGeneral] = useState('');
    const [selectedCategoryMaster, setSelectedCategoryMaster] = useState(null);
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    
    useEffect(() => {
        if(categoriesList){
            setCategories(categoriesList);
        }
    },[categoriesList]);

    useEffect(() => {
        setSelectedCategoryGeneral('TEXTILES');
    }, []);

    useEffect(() => {
        if (categories) {
            const filterCategories = categories.filter((element) => element.general_category === selectedCategoryGeneral);
            setSelectedCategoryMaster(filterCategories[0]);
        }
    }, [selectedCategoryGeneral]);

    const formatsubCategories = (element) => {
        const elements = []
        element?.map((e, idx) => {
            elements.push(e?.master_category)
            e?.categories?.map((el, indx) => {
                elements.push(el.category)
            })
        })
        const elements_ = [...new Set(elements)]
        return elements_
    }

    return ( 
        <Accordion allowMultiple zIndex={9999} position={'relative'} mb={4}>
            <AccordionItem>
                <AccordionButton
                    color={"#064a73"} 
                    fontWeight={500} 
                    width={isGreaterThanMd ? '164px' : '-webkit-fill-available'}
                    fontSize={"14px"} 
                    border={"transparent"}
                    _hover={{
                        cursor: "pointer"
                    }}
                    borderRadius={'md'}
                    py={3}
                >
                    <Box as="span" flex='1' textAlign='center' >
                        Categorías
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={0} px={0}>
                    <Accordion allowMultiple>
                        {categories && categories.map((e, idx) => (
                            <AccordionItem>
                                {({ isExpanded }) => (
                                <>
                                    <h2>
                                    <AccordionButton>
                                        <Box as="span" flex='1' textAlign='left'>
                                        {capitalizeFirstLetter(e.general_category)}
                                        </Box>
                                        {isExpanded ? (
                                        <MinusIcon fontSize='12px' />
                                        ) : (
                                        <AddIcon fontSize='12px' />
                                        )}
                                    </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        {
                                            formatsubCategories(e.master_category)?.map((element, idx) => (
                                                <Text 
                                                    mb={2}
                                                    fontWeight={600}
                                                    fontSize={"14px"}
                                                    onClick={() => {
                                                        window.location.href = `/categoria/${element}`
                                                    }}
                                                    _hover={{
                                                        cursor: "pointer"
                                                    }}>
                                                    {capitalizeFirstLetter(element)}
                                                </Text>
                                            ))
                                        }
                                    </AccordionPanel>
                                </>
                                )}
                            </AccordionItem>
                        ))}
                    </Accordion>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
}
 
export default ButtonCategoriesMb;
