// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.points:hover {
    cursor: pointer;
}

.section-btn-presentacion {
    opacity: 0.9;
}`, "",{"version":3,"sources":["webpack://./src/styles/presentacion.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".points:hover {\n    cursor: pointer;\n}\n\n.section-btn-presentacion {\n    opacity: 0.9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
