// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-swipeable-carousel {
  position: relative;
  overflow: hidden;
}

.react-swipeable-carousel__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;
}

.react-swipeable-carousel__item {
  width: 100%;
}

/* Selector para controlar el menu de puntos del carusel y no se desborde */

.css-teossa > div:nth-child(2) > div {
  padding: 0 0 0 2rem;
  box-sizing: content-box;
}

@media screen and (max-width: 600px)  {
  .css-teossa > div:nth-child(2) > div {
    padding: 0;
  }
}

@media screen and (max-width: 425px)  {
  .css-teossa > div:nth-child(2) > div {
    margin-right: -0.9rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Carousel/Carousel.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA,2EAA2E;;AAE3E;EACE,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".react-swipeable-carousel {\n  position: relative;\n  overflow: hidden;\n}\n\n.react-swipeable-carousel__wrapper {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  overflow: hidden;\n  position: relative;\n}\n\n.react-swipeable-carousel__item {\n  width: 100%;\n}\n\n/* Selector para controlar el menu de puntos del carusel y no se desborde */\n\n.css-teossa > div:nth-child(2) > div {\n  padding: 0 0 0 2rem;\n  box-sizing: content-box;\n}\n\n@media screen and (max-width: 600px)  {\n  .css-teossa > div:nth-child(2) > div {\n    padding: 0;\n  }\n}\n\n@media screen and (max-width: 425px)  {\n  .css-teossa > div:nth-child(2) > div {\n    margin-right: -0.9rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
