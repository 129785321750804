import React, { useState, useEffect } from 'react';
import {
  Flex,
  Box,
  useTheme,
  useMediaQuery,
  Text,
  Button,
} from '@chakra-ui/react';

import '../styles/styled.css';
import '../styles/presentacion.css';

import newBanner from '../assets/images/banner/new_banner.png';
import bgSquare from '../assets/images/fondo/circle_bg.png';
import bg1 from '../assets/images/banner/banner1.png';
import bg2 from '../assets/images/banner/banner2.png';
import bg3 from '../assets/images/banner/banner3.png';

import SearchBar from './SearchBar';
import SearchBarResponsive from './SearchBar/SearchBarResponsive';

const Presentacion = (props) => {
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [current, setCurrent] = useState(0);
  const [dotClicked, setDotClicked] = useState(false);

  const [screenSize, getDimension] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const setDimensions = () => {
    getDimension({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  const slides = [
    {
      key: 'hero1',
      imageUrl: bg1,
    },
    {
      key: 'hero2',
      imageUrl: bg2,
    },
    {
      key: 'hero3',
      imageUrl: bg3,
    },
  ];

  useEffect(() => {
    if (!dotClicked) {
      const intervalId = setInterval(() => {
        if (current === slides.length - 1) {
          setCurrent(0);
        } else {
          setCurrent(current + 1);
        }
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [dotClicked, current, slides.length]);

  useEffect(() => {
    window.addEventListener('resize', setDimensions);

    return () => {
      window.removeEventListener('resize', setDimensions);
    };
  }, [screenSize]);

  const changeBanner = (num) => {
    if (num > 2) {
      setCurrent(0);
    } else if (num < 0) {
      setCurrent(2);
    } else {
      setCurrent(num);
    }
  };

  return (
    <>
      <Box
        id="proyecto"
        w="full"
        mx="auto"
        maxW="3x1"
        {...props}
        position="relative"
      >
        <Flex>
          <Flex
            w="50%"
            backgroundImage={`url(${bgSquare})`}
            backgroundSize="cover"
            backgroundPosition="center center"
            backgroundRepeat="no-repeat"
            display={'flex'}
            alignItems={'center'}
            justifyContent={'left'}
          >
            <Text
              whiteSpace="pre-line"
              color={'#103665'}
              fontWeight={'bold'}
              lineHeight={'1.5em'}
              px={{ base: 5, md: 20, lg: 20, xl: 20 }}
              fontSize={{ base: 20, md: 50, lg: 50, xl: 50 }}
            >
              Más de{'\n'}5,000{'\n'}productos{'\n'}promocionales {'\n'} {'\n'}
              {'\n'}
              <Button
                colorScheme="teal"
                size={{ base: 'sm', md: 'lg', lg: 'lg', xl: 'lg' }}
                onClick={() => (window.location.href = '/categoria/Todas')}
              >
                VER CATÁLOGO
              </Button>
            </Text>
          </Flex>
          <Flex
            w="50%"
            h={isGreaterThanMd ? '750px' : '356px'}
            id="fondo"
            backgroundImage={`url(${newBanner})`}
            backgroundSize={{
              base: 'cover',
              md: 'contain',
              lg: 'contain',
              xl: 'contain',
            }}
            backgroundPosition="center center"
            backgroundRepeat="no-repeat"
            backgroundColor="gray.100"
            position="relative"
            p="0"
            color={'#FFFFFF'}
          ></Flex>
        </Flex>
      </Box>
      <Flex justifyContent="center">
        <Text
          fontSize={{
            base: '20px',
            md: '50px',
            lg: '50px',
            xl: '50px',
          }}
          color={'#014880'}
          fontWeight={1000}
          textShadow={{
            base: 'none',
            md: '2px 0px currentColor',
            lg: '2px 0px currentColor',
            xl: '2px 0px currentColor',
          }}
          letterSpacing={'2px'}
          marginTop={'2.5%'}
          marginBottom={'2.5%'}
        >
          ¿Ya sabes lo que necesitas?
        </Text>
      </Flex>
      <Flex
        display={'flex'}
        justifyContent="center"
        bottom={'60'}
        w="100%"
        left="0"
        right="0"
      >
        <Flex
          width={{
            base: '60%',
            md: '30%',
            lg: '30%',
            xl: '30%',
          }}
        >
          <SearchBar />
        </Flex>
      </Flex>
    </>
  );
};

export default Presentacion;
