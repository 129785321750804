import React, { useState, useEffect } from 'react';
import { Flex, useTheme, useMediaQuery, Text, Link } from '@chakra-ui/react';
import Presentacion from '../../components/Presentacion';
import RecommendedProducts from '../../components/RecommendedProducts';
import FeaturedCategories from '../../components/FeaturedCategories';
import Footer from '../../components/Footer';
import KitsProduct from '../../components/KitsProduct';

import { categoriesTemplate } from '../../resource';

import { useGetFavoritesMMutation } from '../../hooks/enbaapi';

import treintaAnos from '../../assets/images/banner/treinta-anos.png';
import club from '../../assets/images/banner/club.png';
import clubMovil from '../../assets/images/banner/club-movil.png';
import faqLogo from '../../assets/icons/faq.png';
import valores from '../../assets/images/banner/valores.png';
import valoresMovil from '../../assets/images/banner/valores-movil.png';
import CookieConsent from 'react-cookie-consent';

const Home = () => {
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [productsData, setProductsData] = useState(null);
  const [getFavorites] = useGetFavoritesMMutation();

  useEffect(() => {
    getFavorites()
      .unwrap()
      .then((data) => {
        if (data) {
          setProductsData(data);
        }
      });
  }, []);

  return (
    <>
      <CookieConsent
        buttonText={'Acepto'}
        style={{ background: '#d2ebff', color: '#103665' }}
        buttonStyle={{ background: '#064a73', color: 'white' }}
      >
        Este sitio web utiliza cookies para mejorar la experiencia de usuario.
      </CookieConsent>
      <Presentacion />
      <Flex
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={isGreaterThanMd ? '3rem 6rem' : '1rem 0.8rem'}
      >
        <RecommendedProducts titleSection={''} data={productsData} />
        <Flex>
          <Text
            textAlign={{
              base: 'center',
              md: 'initial',
              lg: 'initial',
              xl: 'initial',
            }}
            fontStyle={'italic'}
            color={'#c7d7e4'}
            fontSize={{ base: '35px', md: '70px', lg: '70px', xl: '70px' }}
          >
            Los más vendidos de este verano
          </Text>
        </Flex>
      </Flex>
      <Flex
        w="100%"
        backgroundImage={`url(${treintaAnos})`}
        h={isGreaterThanMd ? '750px' : '356px'}
        backgroundSize="contain"
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
      ></Flex>
      <Flex
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={isGreaterThanMd ? '3rem 6rem' : '1rem 0.8rem'}
      >
        <Flex>
          <Text
            fontWeight={1000}
            textAlign={{
              base: 'center',
              md: 'initial',
              lg: 'initial',
              xl: 'initial',
            }}
            color={'#296393'}
            fontSize={{ base: '25px', md: '50px', lg: '50px', xl: '50px' }}
          >
            ¿Necesitas ayuda?
          </Text>
        </Flex>
        <Flex marginTop={'2%'}>
          <Flex padding={5}>
            <Text
              color={'#4abce1'}
              fontSize={{ base: '20px', md: '40px', lg: '40px', xl: '40px' }}
              textDecor={'underline'}
              textAlign={'left'}
            >
              Tu campaña de{' '}
              <b style={{ fontWeight: 1000 }}>artículos promocionales</b> está a
              un paso de tener éxito
            </Text>
            <Flex>
              <Link
                textDecoration={'none'}
                href="https://www.facebook.com/share/g/5sfwM6uziqByLV6P/"
                target="_blank"
              >
                <img width={'60%'} style={{ margin: '0 auto' }} src={faqLogo} />
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <FeaturedCategories titleSection={''} data={categoriesTemplate} />
      <KitsProduct titleSection={''} />
      <Flex
        w="100%"
        backgroundImage={{
          base: `url(${clubMovil})`,
          md: `url(${club})`,
          lg: `url(${club})`,
          xl: `url(${club})`,
        }}
        h={isGreaterThanMd ? '750px' : '400px'}
        backgroundSize="contain"
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
        onClick={() =>
          window.open(
            'https://www.facebook.com/groups/512767624476701/',
            '_blank',
          )
        }
      ></Flex>
      <Flex
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={isGreaterThanMd ? '3rem 6rem' : '1rem 0.8rem'}
      >
        <Flex
          w="100%"
          backgroundImage={{
            base: `url(${valoresMovil})`,
            md: `url(${valores})`,
            lg: `url(${valores})`,
            xl: `url(${valores})`,
          }}
          h={isGreaterThanMd ? '400px' : '356px'}
          backgroundSize="contain"
          backgroundPosition="center center"
          backgroundRepeat="no-repeat"
        ></Flex>
      </Flex>
      <Footer />
    </>
  );
};

export default Home;
