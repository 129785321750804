// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
  border-radius: 22px;
}

.pagination button {
  background-color: #FFF;
  color: #000;
  border: none;
  padding: 5px 10px;
  border-radius: 22px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #EFEFEF;
}

.pagination .active button {
  background-color: #064A73;
  color: #FFF;
}

.pagination .disabled button {
  background-color: #ddd;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/pages/PopularCategories/styled.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".pagination {\n  list-style: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.pagination li {\n  margin: 0 5px;\n  border-radius: 22px;\n}\n\n.pagination button {\n  background-color: #FFF;\n  color: #000;\n  border: none;\n  padding: 5px 10px;\n  border-radius: 22px;\n  cursor: pointer;\n}\n\n.pagination button:hover {\n  background-color: #EFEFEF;\n}\n\n.pagination .active button {\n  background-color: #064A73;\n  color: #FFF;\n}\n\n.pagination .disabled button {\n  background-color: #ddd;\n  cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
