import React, { useEffect, useState } from 'react';
import {
  Flex,
  Input,
  useTheme,
  Icon,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { categoriesList } from '../../resource/save';

const SearchBar = () => {
  const { breakpoints } = useTheme();
  const { onClose } = useDisclosure();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

  const [categories, setCategories] = useState(null);
  const [selectedCategoryGeneral, setSelectedCategoryGeneral] = useState('');
  const [selectedCategoryMaster, setSelectedCategoryMaster] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState('Todas');
  const [categoryLabel, setCategoryLabel] = useState('Todas las categorías');
  const [keySearch, setKeySearch] = useState('');

  useEffect(() => {
    if (categoriesList) {
      setCategories(categoriesList);
    }
  }, [categoriesList]);

  useEffect(() => {
    setSelectedCategoryGeneral('TEXTILES');
  }, []);

  useEffect(() => {
    if (categories) {
      const filterCategories = categories.filter(
        (element) => element.general_category === selectedCategoryGeneral,
      );
      setSelectedCategoryMaster(filterCategories[0]);
    }
  }, [selectedCategoryGeneral]);

  useEffect(() => {
    setCategoryLabel(selectedCategory);
  }, [selectedCategory]);

  const searchMkt = () => {
    var searchKeyboard = '';
    if (selectedCategory === 'Todas' && keySearch !== '') {
      searchKeyboard = `/code/${keySearch}`;
    } else if (selectedCategory !== 'Todas' && keySearch === '') {
      searchKeyboard = `/search/${selectedCategory}`;
    } else if (selectedCategory !== 'Todas' && keySearch !== '') {
      searchKeyboard = `/${selectedCategory}/${keySearch}`;
    }
    if (searchKeyboard !== '') {
      window.location.href = `/categoria${searchKeyboard}`;
    } else {
      window.location.href = '/categoria/Todas';
    }
  };

  return (
    <Flex
      width={isGreaterThanMd ? '100%' : '85%'}
      bg={'#FFFF'}
      margin={'10px 0px'}
      borderWidth={'2px 2px 2px 2px'}
      padding={'5px'}
      borderColor={'#236092'}
    >
      <Input
        focusBorderColor="transparent"
        fontSize={'12px'}
        border={'transparent'}
        width={'100%'}
        height={'100%'}
        placeholder=""
        _placeholder={{
          color: '#AFAFAF',
        }}
        value={keySearch}
        onChange={(e) => setKeySearch(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            searchMkt();
          }
        }}
      />
      <Flex alignItems={'center'}>
        <SearchIcon color={'#236092'} />
      </Flex>
    </Flex>
  );
};

export default SearchBar;
