import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  useTheme,
  useMediaQuery,
  GridItem,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import ButtonCategories from './ButtonCategories';
import ButtonCategoriesMb from './ButtonCategoriesMb';

import '../../styles/styled.css';
import '../../styles/nav.css';

import logo from '../../assets/icons/logo.svg';
import whiteLogo from '../../assets/icons/logo-blanco.svg';
import profecoDigital from '../../assets/images/profeco-digital.png';
import ButtonShoppingCart from './ButtonShoppingCart';

import SearchBar from './../SearchBar';
import SearchBarResponsive from './../SearchBar/SearchBarResponsive';

import { FaLinkedinIn, FaFacebookF, FaInstagram } from 'react-icons/fa';

export default function Nav() {
  const menuOne = useDisclosure();
  const menuTwo = useDisclosure();
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [isGreaterThanXL] = useMediaQuery(`(min-width: ${breakpoints.xl})`);
  const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [isGreaterThanSm] = useMediaQuery(`(min-width: ${breakpoints.sm})`);

  return (
    <Box>
      <Box>
        <Flex
          bg={'#042d5b'}
          color={useColorModeValue('gray.600', 'gray.600')}
          minH={'60px'}
          py={{ base: 6 }}
          px={{ base: 5, md: 10, lg: 10, xl: 10 }}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {!isGreaterThanSm && (
            <Flex marginRight={!isGreaterThanMd ? '5%' : '0%'}>
              <img
                style={{ zIndex: 1 }}
                onClick={() => (window.location.href = '/')}
                src={whiteLogo}
                width={isGreaterThanMd ? '100px' : '50px'}
                height="56px"
                alt="logo"
              />
            </Flex>
          )}
          <Link
            textDecoration={'none'}
            href="https://www.linkedin.com/company/grupo-enba"
            target="_blank"
          >
            <Icon
              boxSize={5}
              marginRight={2.5}
              color={'#49b9e1'}
              as={FaLinkedinIn}
            />
          </Link>
          <Link
            textDecoration={'none'}
            href="https://www.facebook.com/marketplace.enba"
            target="_blank"
          >
            <Icon
              boxSize={5}
              marginRight={2.5}
              color={'#49b9e1'}
              as={FaFacebookF}
            />
          </Link>
          <Link
            textDecoration={'none'}
            href="https://www.instagram.com/marketplaceenba"
            target="_blank"
          >
            <Icon
              boxSize={5}
              marginRight={5}
              color={'#49b9e1'}
              as={FaInstagram}
            />
          </Link>
          {/* <img
            style={{ zIndex: 1 }}
            src={profecoDigital}
            width="170px"
            height="75px"
            alt="logo"
          /> */}
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'right' }}>
            <Flex
              zIndex={1}
              display={{ base: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <DesktopNavTwo />
            </Flex>
          </Flex>
          <Flex
            flex={{ base: 0.5, md: 'auto' }}
            justifyContent={'end'}
            display={{ base: 'flex', xl: 'none' }}
          >
            <IconButton
              onClick={menuOne.onToggle}
              zIndex={1}
              icon={
                menuOne.isOpen ? (
                  <CloseIcon w={3} h={3} color="#6F6F6F" />
                ) : (
                  <HamburgerIcon w={5} h={5} color="#6F6F6F" />
                )
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
        </Flex>
        <Collapse in={menuOne.isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
      <Box>
        <Flex
          bg={useColorModeValue('transparent', 'gray.800')}
          color={useColorModeValue('gray.600', 'gray.600')}
          minH={'60px'}
          py={{ base: 6 }}
          px={{ base: 0, md: 10, lg: 10, xl: 10 }}
          align={'center'}
        >
          {isGreaterThanLg && (
            <Flex marginRight={!isGreaterThanMd ? '5%' : '0%'}>
              <img
                style={{ zIndex: 1 }}
                onClick={() => (window.location.href = '/')}
                src={logo}
                width={isGreaterThanMd ? '100px' : '50px'}
                height="56px"
                alt="logo"
              />
            </Flex>
          )}
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'center' }}>
            <Flex
              zIndex={1}
              display={{ base: 'flex', md: 'flex', lg: 'flex', xl: 'flex' }}
            >
              <DesktopNav />
            </Flex>
          </Flex>
            <Flex>
              <ButtonShoppingCart />
            </Flex>
          <Flex
            flex={{ base: 0.5, md: 'auto' }}
            justifyContent={'end'}
            display={{ base: 'flex', xl: 'none' }}
          >
            <IconButton
              onClick={menuTwo.onToggle}
              zIndex={1}
              icon={
                menuTwo.isOpen ? (
                  <CloseIcon w={3} h={3} color="#6F6F6F" />
                ) : (
                  <HamburgerIcon w={5} h={5} color="#6F6F6F" />
                )
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
        </Flex>
        <Collapse in={menuTwo.isOpen} animateOpacity>
          <MobileNavTwo />
        </Collapse>
      </Box>
    </Box>
  );
}

const DesktopNavTwo = () => {
  const { breakpoints } = useTheme();
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

  return (
    <Stack direction={'row'} spacing={4} alignItems="center">
      <Flex gap={14}>
        {isGreaterThanMd &&
          NAV_ITEMS_ONE.map((navItem) => (
            <Box key={navItem.label} alignItems="center">
              <Popover trigger={'hover'} placement={'bottom-start'}>
                <GridItem h={'100%'} pt={2.5}>
                  <PopoverTrigger id="popover" textAlign={'center'}>
                    <Link
                      textAlign={'end'}
                      href={navItem.href ?? '#'}
                      fontSize={'14px'}
                      fontWeight={1000}
                      color={'#FFF'}
                      _hover={{
                        textDecoration: 'underline',
                        color: '#49b9e1',
                      }}
                    >
                      {navItem.label}
                    </Link>
                  </PopoverTrigger>
                </GridItem>

                {navItem.children && (
                  <PopoverContent
                    border={0}
                    boxShadow={'xl'}
                    bg={popoverContentBgColor}
                    p={4}
                    rounded={'xl'}
                    minW={'sm'}
                  >
                    <Stack>
                      {navItem.children.map((child) => (
                        <DesktopSubNav key={child.label} {...child} />
                      ))}
                    </Stack>
                  </PopoverContent>
                )}
              </Popover>
            </Box>
          ))}
      </Flex>
    </Stack>
  );
};

const DesktopNav = () => {
  const { breakpoints } = useTheme();
  const linkHoverColor = useColorModeValue('#424242', '#424242');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [isGreaterThanSm] = useMediaQuery(`(min-width: ${breakpoints.sm})`);

  return (
    <Stack direction={'row'} spacing={4} alignItems="center">
      <Flex gap={14}>
        <GridItem colSpan={1} display={isGreaterThanMd ? 'flex' : 'none'}>
          <ButtonCategories />
        </GridItem>
        <GridItem>
          <Flex
            display={'flex'}
            justifyContent="center"
            bottom={'60'}
            w="100%"
            left="0"
            right="0"
          >
            <Flex justifyContent="center">
              <SearchBar />
            </Flex>
          </Flex>
        </GridItem>
        {isGreaterThanMd &&
          NAV_ITEMS_TWO.map((navItem) => (
            <Box key={navItem.label} alignItems="center">
              <Popover trigger={'hover'} placement={'bottom-start'}>
                <GridItem h={'100%'} pt={2.5}>
                  <PopoverTrigger id="popover" textAlign={'center'}>
                    <Link
                      textAlign={'end'}
                      href={navItem.href ?? '#'}
                      fontSize={'16px'}
                      fontWeight={1000}
                      color={'#236092'}
                      _hover={{
                        textDecoration: 'none',
                        color: linkHoverColor,
                      }}
                    >
                      {navItem.label}
                    </Link>
                  </PopoverTrigger>
                </GridItem>

                {navItem.children && (
                  <PopoverContent
                    border={0}
                    boxShadow={'xl'}
                    bg={popoverContentBgColor}
                    p={4}
                    rounded={'xl'}
                    minW={'sm'}
                  >
                    <Stack>
                      {navItem.children.map((child) => (
                        <DesktopSubNav key={child.label} {...child} />
                      ))}
                    </Stack>
                  </PopoverContent>
                )}
              </Popover>
            </Box>
          ))}
      </Flex>
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNavTwo = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ xl: 'none' }}
    >
      <ButtonCategoriesMb />
      {NAV_ITEMS_TWO.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ xl: 'none' }}
    >
      {NAV_ITEMS_ONE.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        zIndex={1}
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse
        in={isOpen}
        zIndex={1}
        animateOpacity
        style={{ marginTop: '0!important' }}
      >
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS_ONE = [
  {
    label: 'PROYECTOS',
    href: '/proyectos-especiales',
  },
  {
    label: 'CONTACTO',
    href: '/contacto',
  },
  {
    label: 'NOSOTROS',
    href: '/nosotros',
  },
];

const NAV_ITEMS_TWO = [
  {
    label: 'TEMPORALIDADES',
    href: '/temporalidades',
  },
];
