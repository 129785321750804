import React from 'react';
import { Flex, useMediaQuery, useTheme, Link } from '@chakra-ui/react';
import FooterMb from './FooterMb';
import profeco from '../../assets/images/profeco_footer.png';
import stripe from '../../assets/images/stripe_footer.png';

const Footer = () => {
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

  return (
    <Flex
      display={'flex'}
      w={'100%'}
      flexDirection={'column'}
      background={'#014880'}
    >
      <Flex
        color={'#FFF'}
        padding={'3rem'}
        pt={{ base: '0rem', md: '1.5rem', lg: '1.5rem', xl: '1.5rem' }}
      >
        <Flex
          width={'100%'}
          justifyContent={isGreaterThanMd ? 'start' : 'center'}
          height={'fit-content'}
        >
          <Flex ml="5%" mt="5%" w={isGreaterThanMd ? '25%' : ''} fontSize={20}>
            <ul>
              <li
                style={
                  isGreaterThanMd
                    ? { padding: '15px' }
                    : { paddingBottom: '5px' }
                }
              >
                <Link
                  textDecoration={'none'}
                  href="https://enba.mx/aviso_de_privacidad.pdf/"
                >
                  Aviso de privacidad
                </Link>
              </li>
              <li
                style={
                  isGreaterThanMd
                    ? { padding: '15px' }
                    : { paddingBottom: '5px' }
                }
              >
                <Link
                  textDecoration={'none'}
                  href="https://enba.mx/terminos_y_condiciones.pdf/"
                >
                  Términos y condiciones
                </Link>
              </li>
              <li
                style={
                  isGreaterThanMd
                    ? { padding: '15px' }
                    : { paddingBottom: '5px' }
                }
              >
                <Link
                  textDecoration={'none'}
                  href="https://enba.mx/contrato_de_adhesion.pdf/"
                >
                  Política de envíos
                </Link>
              </li>
              <li
                style={
                  isGreaterThanMd
                    ? { padding: '15px' }
                    : { paddingBottom: '5px' }
                }
              >
                <Link
                  textDecoration={'none'}
                  href="https://enba.mx/politica_de_devoluciones.pdf/"
                >
                  Política de devoluciones
                </Link>
              </li>
              <li
                style={
                  isGreaterThanMd
                    ? { padding: '15px' }
                    : { paddingBottom: '5px' }
                }
              >
                <Link
                  textDecoration={'none'}
                  href="https://enba.mx/codigo_de_etica.pdf/"
                >
                  Código de ética
                </Link>
              </li>
            </ul>
          </Flex>
          <Flex
            w="75%"
            flexWrap={isGreaterThanMd ? '' : 'wrap'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {/* <img
              style={
                isGreaterThanMd
                  ? {
                      zIndex: 1,
                      width: '20%',
                      height: '30%',
                      marginRight: '5%',
                    }
                  : {
                      flex: '1 1 100%',
                      zIndex: 1,
                      width: '20%',
                      height: '40%',
                      marginRight: '5%',
                    }
              }
              src={profeco}
            /> */}
            <img
              style={
                isGreaterThanMd
                  ? {
                      zIndex: 1,
                      width: '20%',
                      height: '30%',
                      marginRight: '5%',
                    }
                  : {
                      flex: '1 1 100%',
                      zIndex: 1,
                      width: '20%',
                      height: '40%',
                      marginRight: '5%',
                    }
              }
              src={stripe}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
