// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 480px) {
  .css-dpefyv {
    background-color: transparent !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProductSelect/Mb/styled.scss"],"names":[],"mappings":"AAAA;EACI;IACI,wCAAA;EACN;AACF","sourcesContent":["@media (max-width: 480px) {\n    .css-dpefyv {\n        background-color: transparent !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
