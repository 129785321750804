// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `figure.zoom {
    display: flex;
    background-position: 50% 50%;
    position: relative;
    width: 500px;
    overflow: hidden;
    cursor: zoom-in;
}

figure.zoom img:hover {
    opacity: 0;
}

figure.zoom img {
    transition: opacity .5s;
    display: block;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProductSelect/ZoomImage/zoom.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;IAC5B,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,uBAAuB;IACvB,cAAc;IACd,WAAW;AACf","sourcesContent":["figure.zoom {\n    display: flex;\n    background-position: 50% 50%;\n    position: relative;\n    width: 500px;\n    overflow: hidden;\n    cursor: zoom-in;\n}\n\nfigure.zoom img:hover {\n    opacity: 0;\n}\n\nfigure.zoom img {\n    transition: opacity .5s;\n    display: block;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
