import React from 'react';
import { Box, Flex, Image, Text, Container } from '@chakra-ui/react';

const CategoryCard = ({ category }) => {
  return (
    <Container key={category.id} margin="0" gap="0" padding="0" zIndex={1}>
      <Box
        w="200px"
        h="338px"
        m="2"
        bg={'#F6F6F6'}
        overflow="hidden"
        aria-label={category.title}
        onClick={() =>
          (window.location.href = `/categoria/${category.url_reditect}`)
        }
        cursor="pointer"
      >
        <Flex h={'100%'} flexDirection={'column'} justifyContent={'center'}>
          <Flex justifyContent={'center'} pt={5}>
            <Image
              width={'120px'}
              height={'120px'}
              src={category.url}
              alt={category.title}
            />
          </Flex>
          <Flex direction="column" pt="10" pb="1">
            <Box textAlign={'center'}>
              <Text fontSize="18px" fontWeight={1000} color="#296393">
                {category.title}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Container>
  );
};

export default CategoryCard;
