import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@chakra-ui/react';
import CategoryCard from './CategoryCard';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import logoGif from '../assets/icons/logo.gif';

const CardsRenderer = (categories, status) => {
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [isGreaterThanXL] = useMediaQuery(`(min-width: ${breakpoints.xl})`);

  if (categories.length === 0 && status === 'loaded') {
    return (
      <Stack direction="row" alignItems="center">
        <Box textAlign="center" py={6} px={3}>
          <img src={logoGif} width={'400px'} height={'150px'} alt="Cargando" />
        </Box>
      </Stack>
    );
  } else if (categories.length > 0 && status === 'loaded') {
    if (!isGreaterThanMd && !isGreaterThanLg && !isGreaterThanXL) {
      return <CategoryCard category={categories[0]} />;
    }
    return categories.map((element) => (
      <CategoryCard key={element.id} category={element} />
    ));
  }
};

const FeaturedCategories = ({ titleSection, data, props }) => {
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [isGreaterThanXL] = useMediaQuery(`(min-width: ${breakpoints.xl})`);
  const [page, setPage] = useState(0);
  const [products, setProducts] = useState([]);
  const [status, setStatus] = useState('loaded'); //loading, loaded

  useEffect(() => {
    if (data) {
      if (isGreaterThanXL) {
        setProducts(data.slice(page * 6, (page + 1) * 6));
        setStatus('loaded');
      } else if (isGreaterThanLg) {
        setProducts(data.slice(page * 3, (page + 1) * 3));
        setStatus('loaded');
      } else if (isGreaterThanMd) {
        setProducts(data.slice(page * 2, (page + 1) * 2));
        setStatus('loaded');
      } else {
        setProducts(data.slice(page * 1, (page + 1) * 1));
        setStatus('loaded');
      }
    }
  }, [data, isGreaterThanMd, isGreaterThanXL, isGreaterThanLg]);

  useEffect(() => {
    if (data) {
      if (isGreaterThanXL) {
        setProducts(data.slice(page * 6, (page + 1) * 6));
      } else if (isGreaterThanLg) {
        setProducts(data.slice(page * 3, (page + 1) * 3));
      } else if (isGreaterThanMd) {
        setProducts(data.slice(page * 2, (page + 1) * 2));
      } else {
        setProducts(data.slice(page * 1, (page + 1) * 1));
      }
    }
  }, [page]);

  return (
    <Box
      w={{ base: '100%', md: '90%', lg: '90%', xl: '90%' }}
      mx="auto"
      height="full"
      px={{ base: '2', md: '8' }}
      mb={10}
      {...props}
      background={'#0f3562'}
    >
      <Flex
        flexDirection={isGreaterThanMd ? 'row' : 'column'}
        w={'100%'}
        position={'relative'}
      >
        <Flex w={isGreaterThanMd ? '50%' : '100%'} pl={isGreaterThanMd ? 0 : 2}>
          <Text
            fontSize={isGreaterThanMd ? '26px' : '20px'}
            color="accent.500"
            mb="2"
            mt={'10'}
            fontWeight="600"
          >
            {titleSection}
          </Text>
        </Flex>
      </Flex>
      <Flex
        display={isGreaterThanMd ? 'none' : 'flex'}
        direction="row"
        alignItems="center"
        position={isGreaterThanMd ? 'relative' : 'absolute'}
      ></Flex>
      <Flex direction="column" align="center">
        <Box mt={'2rem'} display={'flex'}>
          {!isGreaterThanLg && (
            <Flex alignItems={'center'}>
              <IconButton
                icon={<ChevronLeftIcon color={'#888888'} />}
                rounded="full"
                border="0"
                colorScheme="brand"
                shadow="md"
                transitionDuration=".3s"
                _hover={{ shadow: 'lg' }}
                isDisabled={page <= 0 ? true : false}
                onClick={() => setPage(page - 1)}
                position="relative"
                bg="#E2E2E2"
                zIndex="2"
                aria-label={`Mostrar categorias página: ${page - 1}`}
              />
            </Flex>
          )}
          <Flex direction="row" alignItems="center">
            {CardsRenderer(products, status)}
          </Flex>
          {!isGreaterThanLg && (
            <Flex alignItems={'center'}>
              <IconButton
                icon={<ChevronRightIcon color={'#888888'} />}
                rounded="full"
                border="0"
                colorScheme="brand"
                shadow="md"
                transitionDuration=".3s"
                _hover={{ shadow: 'lg' }}
                onClick={() => setPage(page + 1)}
                position="relative"
                bg="#E2E2E2"
                zIndex="2"
                aria-label={`Mostrar categorias página: ${page + 1}`}
                isDisabled={page >= 5 ? true : false}
              />
            </Flex>
          )}
        </Box>
        <Text
          color={'#FFF'}
          fontWeight={'bold'}
          textAlign={'center'}
          fontSize={isGreaterThanMd ? '30px' : '16px'}
          mt={'10'}
          mb="10"
        >
          Navega por tus categorías de artículos promocionales
        </Text>
      </Flex>
    </Box>
  );
};

export default FeaturedCategories;
