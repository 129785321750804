import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    selectProducts, 
    selectKits, 
    setTotalAmount, 
    selectTotalBreakdown, 
    selectHasWholesale,
    setTotalBreakdown,  
    setTotalAmountRetail,
    setTotalAmountWholesale,
    setDiscountDetails,
    setHasWholesale,
    setProducts,
    setKits
} from '../../hooks/slices/counterSlice';
import { 
    Flex,
    Box,
    Text,
    Button,
    Alert,
    AlertIcon,
    Grid, 
    GridItem
} from '@chakra-ui/react';
import { formatterValue } from '../../resource/validate';
import Footer from '../../components/Footer';
import ProductsMb from './ProductsMb';
import KitsMb from './KitsMb';

const CarritoMb = ({ props }) => {
    const productsStore = useSelector(selectProducts);
    const kitsStore = useSelector(selectKits);
    const totalBreakdown = useSelector(selectTotalBreakdown);
    const hasWholesale = useSelector(selectHasWholesale);
    const dispatch = useDispatch();
    
    const [subTotalSum, setSubTotalSum] = useState(0);
    const [sumIva, setSumIva] = useState(0);
    const [sumShopping, setSumShopping] = useState(0);
    const [sumTotalOrder, setSumTotalOrder] = useState(0);

    /*const calculateSend = () => {
        if (subTotalSum <= 3000) {
            return 199;
        } else if (subTotalSum >= 3000 && subTotalSum <= 10000) {
            return 99;
        } else if (subTotalSum > 10000) {
            return 0;
        }
    }

    useEffect(() => {
        if (productsStore.length > 0) {
            setProducts(productsStore);
        }
        if (kitsStore.length > 0) {
            setKits(kitsStore);
        }
    }, []);*/

    /*useEffect(() => {
        if (products.length > 0 || kits.length > 0) {
            let sumP = 0;
            let sumK = 0;
            let sums = 0;
            let sumsIv = 0;
            let sumsSp = 0;
            if (products.length > 0) {
                products.forEach((elementP) => {
                    sumP = elementP.total_price + sumP;
                });
            }
            if (kits.length > 0) {
                kits.forEach((elementK) => {
                    sumK = elementK.sum_discount_kit + sumK;
                });
            }
            sums = sumP + sumK;
            sumsIv = sums * 0.16;
            sumsSp = calculateSend();
            setSubTotalSum(sums);
            setSumIva(sumsIv);
            setSumShopping(sumsSp);
            setSumTotalOrder(sums + sumsIv + sumsSp);
            dispatch(
                setTotalAmount({totalAmount: subTotalSum})
            )
        }
    }, [products, kits]);*/

    const validateMinShop = () => {
        if (totalBreakdown?.total_amount < 100) {
            return true;
        } 
        return false;
    }

    useEffect(() => {
        if(productsStore.length === 0 && kitsStore.length === 0 && totalBreakdown.total_amount > 0) {
            dispatch(
                setTotalAmount({totalAmount: 0})
            )
            dispatch(
                setTotalAmountRetail({totalAmountRetail: 0})
            )
            dispatch(
                setTotalAmountWholesale({totalAmountWholesale: 0})
            )
            dispatch(
                setHasWholesale({hasWholesale: false})
            )
            dispatch(
                setTotalBreakdown({totalBreakdown: {
                    subtotal: 0,
                    total_discount: 0,
                    total_iva: 0,
                    total_printing: 0,
                    total_shipping: 0,
                    total_amount: 0,
                }
            }))
            dispatch(
                setDiscountDetails({
                    discountDetails: {
                        discount_code: '',
                        discount_percentage: 0,
                        discount_amount: 0,
                    },
                })
            )
        }
    },[kitsStore, productsStore]);

    useEffect(() => {
        if(productsStore.length > 0 || kitsStore.length > 0) {
            calculateTotals();
        }
    },[productsStore, kitsStore])

    useEffect(() => {
        handleChangePrices();
        //console.log({totalBreakdown, hasWholesale, productsStore, kitsStore})
    },[hasWholesale])

    const handleChangePrices = () => {
        let products_ = [...productsStore]
        let kits_ = [...kitsStore]
        if(hasWholesale){
            products_.forEach((product, idx) => {
                let product_ = {...product}
                product_.unit_price = product_?.wholesale_price
                product_.total_price = product_?.wholesale_price * product_?.quantity
                products_[idx] = product_
            })
            kits_.forEach((kit, idx) => {
                let kit_ = {...kit}
                let total = kit_.unitary_wholesale_price * kit_.total_kits
                let discount = total * 0.05
                kit_.sub_sum_total_kit = total.toFixed(2);
                kit_.sum_total = total.toFixed(2);
                kit_.sum_total_kit = total.toFixed(2);
                kit_.sum_discount_kit = total - discount;
                kit_.discount_kit = discount;
                kit_.unitary_kit_price = kit_.unitary_wholesale_price
                kits_[idx] = kit_
            })
        }else{
            products_.forEach((product, idx) => {
                let product_ = {...product}
                product_.unit_price = product_?.retail_price
                product_.total_price = product_?.retail_price * product_?.quantity
                products_[idx] = product_
            })
            kits_.forEach((kit, idx) => {
                let kit_ = {...kit}
                let total = kit_.unitary_retail_price * kit_.total_kits
                let discount = total * 0.05
                kit_.sub_sum_total_kit = total.toFixed(2);
                kit_.sum_total = total.toFixed(2);
                kit_.sum_total_kit = total.toFixed(2);
                kit_.sum_discount_kit = total - discount;
                kit_.discount_kit = discount;
                kit_.unitary_kit_price = kit_.unitary_retail_price
                kits_[idx] = kit_
            })
        }
        dispatch(
            setProducts({products: [
                ...products_
            ]})
        )
        dispatch(
            setKits({kits: [
                ...kits_
            ]})
        )
    }


    const calculateTotals = () => {
        let subtotal_wholesale = 0
        let subtotal_retail = 0
        let iva_wholesale = 0
        let iva_retail = 0
        let shipping_wholesale = 199
        let shipping_retail = 199
        let total_printing = 0
        let total = 0
        let total_dicount_wholesale = 0
        let total_dicount_retail = 0

        productsStore.forEach(product_ => {
            subtotal_retail += parseFloat(product_.retail_price) * parseInt(product_.quantity)
            subtotal_wholesale += parseFloat(product_.wholesale_price) * parseInt(product_.quantity)
            total_printing += parseFloat(product_.printing.price) * parseInt(product_.quantity)
        })

        kitsStore.forEach(kit => {
            let total_kit_wholesale = 0;
            let total_kit_retail = 0;
            kit?.items?.forEach(item => {
                total_kit_wholesale += parseFloat(item?.wholesale_price) * parseInt(kit?.total_kits) 
                total_kit_retail += parseFloat(item?.retail_price) * parseInt(kit?.total_kits)
            })
            total_dicount_retail += total_kit_retail * 0.05
            total_dicount_wholesale += total_kit_wholesale * 0.05
            total_kit_retail = total_kit_retail - (total_kit_retail * 0.05)
            total_kit_wholesale = total_kit_wholesale - (total_kit_wholesale * 0.05)
            subtotal_retail += total_kit_retail
            subtotal_wholesale += total_kit_wholesale
        })

        subtotal_retail += total_printing
        subtotal_wholesale += total_printing
        iva_retail = subtotal_retail * 0.16
        iva_wholesale = subtotal_wholesale * 0.16
        let total_whitout_shipping_retail = subtotal_retail + iva_retail
        let total_whitout_shipping_wholesale = subtotal_wholesale + iva_wholesale
        
        if (total_whitout_shipping_retail <= 3000) {
            shipping_retail = 199;
        } else if (total_whitout_shipping_retail >= 3001 && total_whitout_shipping_retail <= 9999) {
            shipping_retail = 99;
        } else if (total_whitout_shipping_retail >= 10000) {
            shipping_retail = 0;
        }

        if (total_whitout_shipping_wholesale <= 3000) {
            shipping_wholesale = 199;
        } else if (total_whitout_shipping_wholesale >= 3001 && total_whitout_shipping_wholesale <= 9999) {
            shipping_wholesale = 99;
        } else if (total_whitout_shipping_wholesale >= 10000) {
            shipping_wholesale = 0;
        }

        if((total_whitout_shipping_retail + shipping_retail) > 15000){
            total = total_whitout_shipping_wholesale + shipping_wholesale
            const totalBreakdown ={
                subtotal: subtotal_wholesale,
                total_discount: 0,
                total_discount_kits: total_dicount_wholesale,
                total_iva: iva_wholesale,
                total_printing: total_printing,
                total_shipping: shipping_wholesale,
                total_amount: total,
            }
            dispatch(setTotalBreakdown({totalBreakdown}))
            dispatch(setHasWholesale({hasWholesale: true}))
        }else{
            total = total_whitout_shipping_retail + shipping_retail
            const totalBreakdown ={
                subtotal: subtotal_retail,
                total_discount: 0,
                total_discount_kits: total_dicount_retail,
                total_iva: iva_retail,
                total_printing: total_printing,
                total_shipping: shipping_retail,
                total_amount: total,
            }
            dispatch(setTotalBreakdown({totalBreakdown}))
            dispatch(setHasWholesale({hasWholesale: false}))
        }
        dispatch(setTotalAmountRetail({totalAmountRetail: (total_whitout_shipping_retail + shipping_retail)}))
        dispatch(setTotalAmountWholesale({totalAmountWholesale: (total_whitout_shipping_wholesale + shipping_wholesale)}))
        dispatch(setTotalAmount({totalAmount: total}))
    }


    return ( 
        <>
            <Box color={"#424242"} w="full" mx="auto" maxW="3x1" {...props} padding={"2rem 0.5rem"} position="relative">
                <Text mb={10} fontSize='xl' fontWeight={700}>Mi orden</Text>
                <Flex w={"100%"} flexDirection={"column"} display={productsStore?.length > 0 ? "flex" : "none"}>
                    <Grid templateColumns='repeat(1, 1fr)'>
                        {productsStore && productsStore.map((item, idx) => {
                            return (
                                <GridItem key={idx} w='100%' pl={2} pr={2}>
                                    <ProductsMb 
                                        setSumIva={setSumIva}
                                        setSumShopping={setSumShopping}
                                        product={item}
                                        setSubTotalSum={setSubTotalSum}
                                        setSumTotalOrder={setSumTotalOrder}
                                        idx={idx}
                                    />
                                </GridItem>
                            )
                        })}
                    </Grid>
                </Flex>
                <Flex w={"100%"} flexDirection={"column"} display={kitsStore?.length > 0 ? "flex" : "none"}>
                    <Grid templateColumns='repeat(1, 1fr)'>
                        {kitsStore && kitsStore.map((item, idx) => {
                            return (
                                <GridItem key={idx} w='100%' pl={2} pr={2}>
                                    <KitsMb 
                                        kits={kitsStore}
                                        kit={item}
                                        idx={idx}
                                        setSumIva={setSumIva}
                                        setSumShopping={setSumShopping}
                                        setSubTotalSum={setSubTotalSum}
                                        setSumTotalOrder={setSumTotalOrder}
                                    />
                                </GridItem>
                            )
                        })}
                    </Grid>
                </Flex>
                <Flex flexDirection={"column"} w={"100%"} mt={5} pl={2} pr={2}>
                    <Flex borderTop={"1px solid #E2E2E2"} pt={3} mb={5}>
                        <Flex w={"40%"}>
                            <Text fontSize={"lg"} fontWeight={500}>Subtotal</Text>
                        </Flex>
                        <Flex w={"70%"} justifyContent={"end"}>
                            <Text fontSize={"lg"} fontWeight={500}>{formatterValue(totalBreakdown?.subtotal || 0)}</Text>
                        </Flex>
                    </Flex>
                    <Flex borderTop={"1px solid #E2E2E2"} pt={3} mb={5}>
                        <Flex w={"40%"} flexDirection={"column"}>
                            <Text color={"#828282"} fontSize={"14px"} fontWeight={400}>{"IVA (16%)"}</Text>
                            <Text color={"#828282"} mt={3} fontSize={"14px"} fontWeight={400}>Costo de envío</Text>
                            <Text color={"#828282"} mt={3} fontSize={"14px"} fontWeight={400}>Descuento</Text>
                        </Flex>
                        <Flex w={"70%"} flexDirection={"column"} alignItems={"end"}>
                            <Text fontSize={"14px"} fontWeight={500}>{formatterValue(totalBreakdown?.total_iva || 0)}</Text>
                            <Text fontSize={"14px"} mt={3} fontWeight={500}>{formatterValue(totalBreakdown?.total_shipping || 0)}</Text>
                            <Text fontSize={"14px"} mt={3} fontWeight={500}>{formatterValue(totalBreakdown?.total_discount || 0)}</Text>
                        </Flex>
                    </Flex>
                    <Flex borderTop={"1px solid #E2E2E2"} pt={3} mb={5}>
                        <Flex w={"40%"}>
                            <Text fontSize={"lg"} fontWeight={500}>Total</Text>
                        </Flex>
                        <Flex w={"70%"} justifyContent={"end"}>
                            <Text fontSize={"lg"} fontWeight={500}>{formatterValue(totalBreakdown?.total_amount || 0)}</Text>
                        </Flex>
                    </Flex>
                    <Flex mt={5}>
                        {validateMinShop() ? 
                            <Alert status='error' lineHeight={1.2}>
                                <AlertIcon />
                                No es posible realizar el proceso, el mínimo de compra debe ser $1,500.00 MXN
                            </Alert>
                            : 
                            <Button 
                                width={"100%"}
                                fontWeight={600} fontSize={"18px"} 
                                height={"48px"}
                                onClick={() => window.location.href = '/productos/cotizar'}>
                                Continuar
                            </Button>
                        }
                    </Flex>
                    <Flex mt={3}>
                        <Button borderColor={"accent.500"} marginTop={3}
                            width={"100%"}
                            fontWeight={600} fontSize={"18px"} 
                            height={"48px"} variant={"outline"}
                            onClick={() => window.open('/download', '_blank')}
                            isDisabled={validateMinShop() ? true : false}>
                            Descargar cotización
                        </Button>
                    </Flex>
                </Flex>
            </Box>
            <Footer />
        </>
    );
}
 
export default CarritoMb;