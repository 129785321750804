// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1750px);
  }
}
.slider {
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 95%;
}
.slider::before, .slider::after {
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 50%);
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  animation: scroll 20s linear infinite;
  display: flex;
  width: 3500px;
  margin-bottom: 2.5rem;
}
.slider .slide {
  height: auto;
  width: auto;
  padding: 0px 5rem;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/CarouselOurWork/styled.scss"],"names":[],"mappings":"AAOA;EACC;IAAK,wBAAA;EALJ;EAMD;IAAO,8BAAA;EAHN;AACF;AAOA;EACC,aAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;AALD;AAOC;EApBA,wFAAA;EAuBC,WAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;AANF;AASC;EACC,QAAA;EACA,MAAA;EACA,0BAAA;AAPF;AAUC;EACC,OAAA;EACA,MAAA;AARF;AAWC;EACC,qCAAA;EACA,aAAA;EACA,aAAA;EACE,qBAAA;AATJ;AAYC;EACC,YAAA;EACA,WAAA;EACE,iBAAA;EACA,aAAA;AAVJ","sourcesContent":["@mixin white-gradient {\n\tbackground: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 50%);\n}\n\n$animationSpeed: 20s;\n\n// Animation\n@keyframes scroll {\n\t0% { transform: translateX(0); }\n\t100% { transform: translateX(calc(-250px * 7))}\n}\n\n\n// Styling\n.slider {\n\theight: 100px;\n\tmargin: auto;\n\toverflow:hidden;\n\tposition: relative;\n\twidth: 95%;\n\t\n\t&::before,\n\t&::after {\n\t\t@include white-gradient;\n\t\tcontent: \"\";\n\t\theight: 100px;\n\t\tposition: absolute;\n\t\twidth: 200px;\n\t\tz-index: 2;\n\t}\n\t\n\t&::after {\n\t\tright: 0;\n\t\ttop: 0;\n\t\ttransform: rotateZ(180deg);\n\t}\n\n\t&::before {\n\t\tleft: 0;\n\t\ttop: 0;\n\t}\n\t\n\t.slide-track {\n\t\tanimation: scroll $animationSpeed linear infinite;\n\t\tdisplay: flex;\n\t\twidth: calc(250px * 14);\n    margin-bottom: 2.5rem;\n\t}\n\t\n\t.slide {\n\t\theight: auto;\n\t\twidth: auto;\n    padding: 0px 5rem;\n    display: flex;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
