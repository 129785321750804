import React, { useEffect, useState } from 'react';
import {
  Box,
  HStack,
  Flex,
  Image,
  Text,
  Tag,
  Container,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { formatterValue } from '../resource/validate';

const KitCard = ({ product }) => {
  const [price, setPrice] = useState(0);
  const [imgList, setImgList] = useState([]);

  useEffect(() => {
    const min_prices = [];
    let imgCollageList = [];
    product.products.forEach((element, idx) => {
      let min_price = 9999999;
      element.prices.map((e) => {
        if (e.wholesale_price < min_price) {
          min_price = e.wholesale_price;
        }
      });
      if (min_price !== 9999999) {
        min_prices.push(min_price);
      }
      imgCollageList = [
        ...imgCollageList,
        {
          id: idx,
          imgUrl: element.images[0]?.images?.images_item[0],
        },
      ];
    });
    setImgList(imgCollageList);
    let total = 0;
    min_prices.map((e) => {
      total += parseFloat(e);
    });
    setPrice(total.toFixed(2));
  }, [product]);

  return (
    <Container key={product.id} margin="0" gap="0" padding="0" zIndex={1}>
      <Box
        w="294px"
        h="350px"
        m="2"
        overflow="hidden"
        cursor="pointer"
        onClick={() =>
          (window.location.href = `/kit/${product ? product.name : ''}`)
        }
        aria-label={product.name}
      >
        <Grid
          templateColumns="repeat(2, 2fr)"
          w={'100%'}
          h={'196px'}
          background={'#FFF'}
        >
          {imgList.length >= 4 &&
            imgList.map((item, idx) =>
              item.imgUrl ? (
                <GridItem key={idx}>
                  <Flex w={'100%'} justifyContent={'center'}>
                    <Image
                      w={'96px'}
                      h={'96px'}
                      src={item.imgUrl}
                      alt={'kit1'}
                    />
                  </Flex>
                </GridItem>
              ) : null,
            )}
        </Grid>
        <Flex direction="column" px="4" pt="5" pb="3" background={'#FFF'}>
          <Box title={product.name.toLowerCase()} textAlign={'center'}>
            <Text
              fontSize="18px"
              fontWeight={1000}
              color="#296393"
              lineHeight={'46px'}
              textTransform={'capitalize'}
            >
              {product.name.toLowerCase()}
            </Text>
          </Box>
          <HStack justifyContent={'center'}>
            <Text
              textAlign={'center'}
              fontSize="14px"
              color="#424242"
              fontWeight={1000}
              isTruncated
            >
              Desde
              <Text fontSize={'20px'} fontWeight={1000} color={'#296393'}>
                <br />
                {formatterValue(price)}{' '}
                <Text
                  fontSize={'14px'}
                  fontWeight={1000}
                  color={'#296393'}
                  display={'inline-block'}
                >
                  + IVA
                </Text>
              </Text>
            </Text>
          </HStack>
        </Flex>
      </Box>
    </Container>
  );
};

export default KitCard;
