import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProducts, selectKits } from '../../hooks/slices/counterSlice';
import {
  IconButton,
  Text,
  Flex,
  useTheme,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react';
import { FaShoppingBag } from 'react-icons/fa';

const ButtonShoppingCart = () => {
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
  const [isGreaterThanXL] = useMediaQuery(`(min-width: ${breakpoints.xl})`);
  const productsStore = useSelector(selectProducts);
  const kitsStore = useSelector(selectKits);

  const [numShoppingCart, setNumShoppingCart] = useState(0);

  useEffect(() => {
    setNumShoppingCart(productsStore?.length + kitsStore?.length);
    // setNumShoppingCart(10);
  }, []);

  useEffect(() => {
    setNumShoppingCart(productsStore?.length + kitsStore?.length);
    // setNumShoppingCart(10);
  }, [productsStore, kitsStore]);

  return (
    <Flex
      background={'#2c4c73'}
      padding={3}
      onClick={() => (window.location.href = '/productos/carrito/cotizar')}
      cursor={'pointer'}
    >
      <Text
        fontWeight={600}
        fontSize={'12px'}
        color={'#FFF'}
        display={'flex'}
        alignItems={'center'}
      >
        MI CARRITO
      </Text>
      <Flex
        display={isGreaterThanXL ? 'flex' : 'none'}
        alignItems={'center'}
        position={'relative'}
        background={'#2c4c73'}
      >
        <Flex
          bottom={6}
          left={8}
          borderRadius={'full'}
          p={'2px 6px'}
          zIndex={1}
          position={'absolute'}
          display={numShoppingCart > 0 ? 'flex' : 'none'}
          bg={'#329DDE'}
          color={'#FFF'}
        >
          <Text fontWeight={600} fontSize={'12px'}>
            {numShoppingCart}
          </Text>
        </Flex>
        <Icon
          zIndex={1}
          isRound={true}
          border={'transparent'}
          variant="outline"
          fontSize="26px"
          color={'#FFF'}
          paddingLeft={2}
          as={FaShoppingBag}
        />
      </Flex>
      <Flex
        display={isGreaterThanXL ? 'none' : 'flex'}
        alignItems={'center'}
        position={'relative'}
      >
        <Flex
          bottom={6}
          left={8}
          borderRadius={'full'}
          p={'2px 6px'}
          zIndex={1}
          position={'absolute'}
          display={numShoppingCart > 0 ? 'flex' : 'none'}
          bg={'#329DDE'}
          color={'#FFF'}
        >
          <Text fontWeight={600} fontSize={'12px'}>
            {numShoppingCart}
          </Text>
        </Flex>
        <Icon
          zIndex={1}
          isRound={true}
          border={'transparent'}
          variant="outline"
          fontSize="26px"
          color={'#FFF'}
          paddingLeft={2}
          as={FaShoppingBag}
        />
      </Flex>
    </Flex>
  );
};

export default ButtonShoppingCart;
